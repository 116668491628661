var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10",attrs:{"id":"itemSales"}},[_c('v-app-bar',{attrs:{"app":""}},[_c('v-toolbar-title',[_vm._v("كشف مبيعات المواد")])],1),_c('v-card',{staticClass:"pa-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"من تاريخ","dense":"","clearable":"","hide-details":"","outlined":"","type":"date"},model:{value:(_vm.search.dateFrom),callback:function ($$v) {_vm.$set(_vm.search, "dateFrom", $$v)},expression:"search.dateFrom"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"الى تاريخ","dense":"","clearable":"","hide-details":"","outlined":"","type":"date"},model:{value:(_vm.search.dateTo),callback:function ($$v) {_vm.$set(_vm.search, "dateTo", $$v)},expression:"search.dateTo"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"item-value":"idUser","item-text":"username","hide-details":"","outlined":"","dense":"","placeholder":"المندوب","clearable":"","items":_vm.users},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"dark":"","color":_vm.$background,"block":""},on:{"click":function($event){return _vm.performSearch()}}},[_vm._v(" بحث ")])],1)],1),_c('br'),_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticStyle:{"background-color":"rgb(100, 100, 100)","color":"white"},attrs:{"colspan":"2"}},[_vm._v(" اسم الحساب ")]),_vm._l((_vm.selectedUser == null
              ? _vm.users
              : _vm.users.filter(function (e) { return e.idUser == _vm.selectedUser; })),function(user){return _c('th',{key:user.idUser,staticClass:"text-center",staticStyle:{"background-color":"rgb(202, 248, 184)"},attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(user.username)+" ")])})],2),_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v("المجموعة")]),_vm._l((_vm.selectedUser == null
              ? _vm.users
              : _vm.users.filter(function (e) { return e.idUser == _vm.selectedUser; })),function(user){return [_c('th',{key:'SALES_' + user.idUser,staticStyle:{"background-color":"rgb(100, 248, 184)"}},[_vm._v(" المبيعات ")]),_c('th',{key:'TOTAL_' + user.idUser,staticStyle:{"background-color":"rgb(202, 150, 184)"}},[_vm._v(" الاجمالي ")])]})],2)]),_c('tbody',_vm._l((_vm.itemGroups),function(group,index){return _c('tr',{key:group.idItemGroup},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',{attrs:{"width":"200px"}},[_vm._v(" "+_vm._s(group.itemGroupName)+" ")]),_vm._l((_vm.selectedUser == null
              ? _vm.users
              : _vm.users.filter(function (e) { return e.idUser == _vm.selectedUser; })),function(user){return [_c('td',{key:'ITEMSALES_' + "_" + index + "_" + user.idUser,staticStyle:{"background-color":"rgb(232 232 232)"}},[_vm._v(" "+_vm._s(_vm.getItemCountByUser( user.idUser, group.idItemGroup ).toLocaleString())+" ")]),_c('th',{key:'ITEMTOTAL_' + "_" + index + "_" + user.idUser,staticStyle:{"background-color":"rgb(193 193 193)"}},[_vm._v(" "+_vm._s(_vm.getItemSalesByUser( user.idUser, group.idItemGroup ).toLocaleString())+" ")])]})],2)}),0),_c('tfoot',[_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("المجموع")]),_vm._l((_vm.selectedUser == null
              ? _vm.users
              : _vm.users.filter(function (e) { return e.idUser == _vm.selectedUser; })),function(user,index){return [_c('td',{key:'GROUPEDITEMSALES_' + "_" + index + "_" + user.idUser,staticStyle:{"background-color":"rgb(232 232 232)"}},[_vm._v(" "+_vm._s(_vm.getTotalCount(user.idUser).toLocaleString())+" ")]),_c('th',{key:'GROUPEDITEMTOTAL_' + "_" + index + "_" + user.idUser,staticStyle:{"background-color":"rgb(193 193 193)"}},[_vm._v(" "+_vm._s(_vm.getTotalByUser(user.idUser).toLocaleString())+" ")])]})],2)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }